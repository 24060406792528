.error {
  position: relative;
  top: 0;
  width: 100%;
  overflow: hidden;
}
.custom-control-label {
  font-size: 14px;
  text-transform: capitalize;
  padding-left: 5px;
}
.custom-control {
  padding-left: 8px;
}
.bidModalBody {
  width: 75%;
  margin: 5% auto;
  margin-left: 20%;
  padding: 0px;
}
